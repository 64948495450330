<template>
  <v-container>
    <v-layout justify-center>
      <v-flex xs12 sm12 md11 lg10 xl8>
        <v-data-table
          dense
          :headers="headers"
          :items="compGruposLista"
          sort-by="nome"
          class="elevation-10"
          :search="search"
          :loading="!compGruposProgress.length"
          loading-text="Carregando..."
        >
          <template v-slot:progress>
            <v-progress-linear
              color="primary"
              :height="4"
              indeterminate
            ></v-progress-linear>
          </template>

          <template v-slot:top>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-multiple-outline</v-icon>
              <v-toolbar-title>Grupos do Sistema </v-toolbar-title>
              <v-icon class="ml-2" small>mdi-asterisk</v-icon>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="white"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSyncGruComp()"
                  >
                    mdi-sync
                  </v-icon>
                </template>
                <span>Sincronizar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metRecarregar()"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metSelecionarGrupoEdicao()"
                  >
                    mdi-plus-circle-outline
                  </v-icon>
                </template>
                <span>Novo Grupo</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
            <v-container>
              <v-row dense>
                <v-col cols="6" class="ml-2" align-self="end">
                  <v-chip-group mandatory active-class="primary">
                    <v-chip @click="listaGruAtivos = 'ativos'"> Ativos </v-chip>
                    <v-chip @click="listaGruAtivos = 'inativos'">
                      Inativos
                    </v-chip>
                    <v-chip @click="listaGruAtivos = 'todos'"> Todos </v-chip>
                  </v-chip-group>
                </v-col>
                <v-col cols="4" class="ml-1 mb-0" align-self="end">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Busca"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="blue darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarGrupoEdicao(item)"
                >
                  mdi-lead-pencil
                </v-icon>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="mr-2"
                  color="indigo darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metSelecionarGrupo(item)"
                >
                  mdi-toy-brick-outline
                </v-icon>
              </template>
              <span>Componentes</span>
            </v-tooltip>

            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  :color="metAtivoColor(item.ativo)"
                  v-bind="attrs"
                  v-on="on"
                  @click="metParaAtivacao(item)"
                >
                  {{ item.ativo ? "mdi-check-bold" : "mdi-close-thick" }}
                </v-icon>
              </template>
              <span>{{ item.ativo ? "Ativo" : "Inativo" }}</span>
            </v-tooltip>

            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import register from "./../_store/register";
import ConfirmDialogue from "@/components/ConfirmDialogue.vue";

const { mapGetters, mapState, mapActions } =
  createNamespacedHelpers("adminGrupos");

export default {
  components: { ConfirmDialogue },
  // mixins: [],
  name: "AdminGruposLista",
  data: () => ({
    listaGruAtivos: "ativos",
    search: "",
    dialog: false,
    headers: [
      { text: "Código", value: "id" },

      { text: "Grupo", value: "grupo", align: "start" },
      { text: "Descrição", value: "descricao", align: "start" },
      { text: "Ações", value: "actions", sortable: false },
    ],
  }),

  computed: {
    ...mapState(["erro", "staGrupos", "staGrupoSelecionado"]),
    ...mapGetters(["getGruposAtivos", "getGruposInativos"]),

    compGruposLista() {
      return this.listaGruAtivos == "ativos"
        ? this.getGruposAtivos
        : this.listaGruAtivos == "inativos"
        ? this.getGruposInativos
        : this.staGrupos;
    },
    compGruposProgress() {
      return this.staGrupos;
    },
  },

  created() {
    register(this.$store);
    this.actListarGrupos();
  },

  watch: {
    staErro() {
      if (this.staErro) {
        this.$root.snackbar.show({
          type: "danger",
          message: this.staErro,
        });
        setTimeout(() => {
          this.$router.push({ name: "Login" });
        }, 3500);
      }
    },
  },

  methods: {
    ...mapActions([
      "actSelecionarGrupo",
      "actListarGrupos",
      "actAtivarGrupo",
      "actSyncGruComp",
    ]),

    metSelecionarGrupo(grupo) {
      this.actSelecionarGrupo({ grupo });
      this.$router.push({ name: "AdminGruposCompForm" });
    },

    async metSyncGruComp() {
      await this.$root.snackbar.show({
        type: "warning",
        message: "Sincronização Componentes Grupo Iniciada!",
      });
      await this.actSyncGruComp({
        grupo: "todos",
      });
      await this.$root.snackbar.show({
        type: "success",
        message: "Sincronização Componentes Grupo Finalizada!",
      });
    },

    metAtivoColor(ativo) {
      if (ativo) return "green darken-1";
      else return "red darken-1";
    },

    metSelecionarGrupoEdicao(grupo) {
      this.actSelecionarGrupo({ grupo });
      this.$router.push({ name: "AdminGruposForm" });
    },

    metParaAtivacao(grupo) {
      this.actAtivarGrupo({ grupo });
    },

    metVoltar() {
      this.$router.back();
    },

    metRecarregar() {
      this.actListarGrupos();
      this.$root.snackbar.show({
        type: "info",
        message: "Lista atualizada!",
      });
    },
  },
};
</script>

<style>
/* .tooltip {
  display: flex;
  justify-content: space-around;
} */
</style>
